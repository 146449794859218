@use 'sass:color';
@use 'libs/styles/variables';
@use 'libs/styles/utils';

.button {
  @include variables.paragraph($big: true);
  display: inline-flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  border: 2px solid variables.$color-base;
  border-radius: variables.$border-radius-xxl;
  padding: 0 32px;
  font-weight: 500;
  white-space: nowrap;
  color: variables.$color-dark-light;
  background: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    border-color: color.adjust(variables.$color-purple, $lightness: 25%);
    text-decoration: none;
  }

  &:disabled {
    border: none;
    color: variables.$color-white;
    background-color: variables.$color-disabled;
    cursor: not-allowed;
  }

  &:has(> svg),
  &:has(> img) {
    padding-right: 16px;
    padding-left: 16px;

    &:not(:has(> :only-child)) {
      padding-left: 24px;
    }

    svg {
      width: auto;
      color: rgba(variables.$color-purple, 0.75);
    }
  }

  @media (max-width: variables.$device-md) {
    height: 38px;
  }
}

.float:hover {
  transform: translateY(-6px);
}

.filled {
  border-color: variables.$color-purple;
  color: white;
  background-color: variables.$color-purple;

  &:has(> svg) svg {
    color: white;
  }
}

$colors: (
  'fund': variables.$color-fund,
  'org': variables.$color-org,
  'purple': variables.$color-purple,
  'portfolio': variables.$color-org-2,
  'red': variables.$color-error,
  'faded': color.adjust(variables.$color-slate, $lightness: 20%),
);
$d: color.adjust(variables.$color-white, $lightness: -10%);

@each $name, $color in $colors {
  .variation--#{$name} {
    border-color: $color;
    color: $color;

    &.filled {
      @if color.whiteness($color) <= 50% {
        color: variables.$color-white;
      } @else {
        color: variables.$color-dark;
      }
      border-color: $color;
      background: $color;
    }

    &.faded {
      border: none;
      color: $color;
      background: #{rgba($color, 0.125)};

      &.size--small:has(svg) {
        gap: 0.375rem;
        padding-left: 0.5rem;
      }

      & > svg {
        height: unset;
        color: $color;
        transition: variables.$transition-s;
      }

      &:hover {
        color: white;
        background: $color;

        & > svg {
          color: white;
        }
      }
    }

    &:hover {
      $d: color.adjust($color, $saturation: 10%, $lightness: 10%);
      border-color: color.adjust($color, $lightness: -5%);
      background: $d;
      box-shadow: variables.$box-shadow-medium-blue;
    }

    &:disabled {
      background-color: rgba($color, 0.5);
    }
  }
}

.variation--org {
  &.filled {
    &:hover {
      $d: color.adjust(variables.$color-org, $saturation: 30%, $lightness: 10%);
      border-color: variables.$color-org;
      color: variables.$color-white;
      background: $d;
    }
  }

  &.gradient {
    $bg: linear-gradient(0deg, rgba(255, 255, 255, 20%), rgba(255, 255, 255, 20%)),
      linear-gradient(92.19deg, #ea6b0e -157.92%, #53acde -21.21%, #6356db 120.71%, #d94002 172.93%);
    background: $bg;
    box-shadow: variables.$box-shadow-medium-purple;

    &:hover {
      background: $bg;
      box-shadow: variables.$box-shadow-medium-purple;
      filter: brightness(105%);
    }
  }
}

.variation--allocation {
  &.filled {
    &:hover {
      $d: color.adjust(variables.$color-org, $saturation: 30%, $lightness: 10%);
      border-color: variables.$color-org;
      color: variables.$color-white;
      background: $d;
    }
  }

  &.gradient {
    $bg: linear-gradient(90deg, #fc8935 0.24%, #f87e26 12.24%, #fc718a 35.24%, #cf75cb 52.74%, #ad7aff 94.24%);
    border: 1px solid rgba(variables.$color-purple-very-dark, 0.25);
    background: $bg;
    box-shadow: variables.$box-shadow-medium-purple;

    &:hover {
      border: 1px solid rgba(variables.$color-purple-very-dark, 0.35);
      background: $bg;
      box-shadow: variables.$box-shadow-medium-purple;
      filter: brightness(115%);
    }
  }
}

.variation--fund {
  &.filled {
    &:hover {
      $d: color.adjust(variables.$color-fund, $saturation: 30%, $lightness: 10%);
      color: variables.$color-white;
      background: $d;
      box-shadow: variables.$box-shadow-medium-orange;
    }
  }

  &.gradient {
    $bg: linear-gradient(0deg, rgba(255, 255, 255, 20%), rgba(255, 255, 255, 20%)),
      linear-gradient(92.19deg, variables.$color-fund 10%, variables.$color-fund 50%, #f1392b 90%);
    background: $bg;
    box-shadow: variables.$box-shadow-light-orange;

    &:hover {
      background: $bg;
      box-shadow: variables.$box-shadow-medium-orange;
      filter: brightness(105%);
    }
  }

  &:hover {
    border-color: variables.$color-fund;
  }
}

.variation--red {
  border-color: rgba(variables.$color-error, 0.25);
  color: variables.$color-dark-red;

  &:hover {
    border-color: variables.$color-dark-red;
    color: white;
  }
}

.variation--faded {
  @include utils.increase-specificity {
    color: variables.$color-disabled;
  }

  &.filled {
    &:has(> svg) svg {
      color: rgba(variables.$color-purple, 0.75);
    }
  }
}

.gradient {
  border: none;
  font-weight: bold;
  color: white;
  transition: variables.$transition-m;
}

.shadowed {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 3%);
}

.minimal {
  border: none;
  padding: 0;
  box-shadow: none;

  &:hover {
    border: none;
    background: none;
  }
}

.size {
  &--small {
    height: 32px;
    padding: 0 variables.$space-m;

    & > svg {
      height: 12px;
    }
  }

  &--tiny {
    height: 1.35rem;
    border-width: 1px;
    padding: 0 0.65rem;
    font-size: 0.75rem;

    & > svg {
      height: 12px;
    }
  }

  &--medium {
    height: 48px;
    padding: 0 variables.$space-l;
    font-size: variables.$font-size-m;

    & > svg {
      height: 17px;
    }

    @media (max-width: variables.$device-md) {
      height: 42px;
    }
  }

  &--large {
    height: 120px;
    padding: 0 variables.$space-xl;
  }
}

.font-size {
  &--small,
  &--tiny {
    @include variables.paragraph;
    font-weight: 500;
  }

  &--medium {
    @include variables.header(4);
    font-weight: 500;
  }

  &--large {
    @include variables.header(2);
  }
}

.card--icon {
  display: inline-flex;
  gap: 20px;
  width: 374px;
  border: 1px solid #e4ebf4;
  border-radius: 28px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  white-space: unset;
  background: linear-gradient(180deg, #fff 0%, #fefeff 75.3%, #f9fdff 100%);
  background-color: #fff;
  box-shadow: 0 18px 43px rgba(75, 51, 142, 6%);
  cursor: pointer;

  & > *:last-child {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    text-align: left;
    color: variables.$color-disabled;

    & > *:first-child {
      transition: variables.$transition-s;
    }
  }

  &:hover {
    box-shadow: variables.$box-shadow-medium-purple;

    .icon-div {
      --c: #{color.adjust(variables.$color-purple, $lightness: 25%)};
      border-color: var(--c);
      box-shadow: variables.$box-shadow-medium-purple;

      &.float {
        transform: translateY(-6px);
      }

      & > svg {
        animation: variables.$spin-animation;
      }
    }

    & > *:last-child > *:first-child {
      color: variables.$color-purple;
    }
  }

  @media (max-width: variables.$device-md) {
    width: unset;
  }
}

.icon-div {
  flex: 0 0 76px;
  height: 76px;
  border: 1.5px solid color.adjust(variables.$color-lighter, $lightness: 2.5%);
  border-radius: 100px;
  padding: 16px;
  transition: all 0.2s ease;

  & > svg {
    width: 100%;
    height: 100%;
  }
}
