@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'sass:color';

@mixin chain-colors {
  & {
    color: white;
    transition: variables.$transition-s;

    a:hover,
    button:hover {
      cursor: pointer;
    }

    &:hover {
      filter: brightness(1.2);
    }

    // Ethereum, Goerli, Sepolia, Foundry 1

    &--1,
    &--5,
    &--11155111,
    &--31337 {
      &,
      &:hover {
        background-color: variables.$color-ethereum;

        --shadow-color: #{rgba(variables.$color-ethereum, 0.125)};
      }
    }

    // Optimism, Optimism Goerli

    &--10,
    &--420 {
      &,
      &:hover {
        background-color: variables.$color-optimism;

        --shadow-color: #{rgba(variables.$color-optimism, 0.125)};
      }
    }

    // Polygon, Polygon Mumbai

    &--137,
    &--80001 {
      &,
      &:hover {
        background-color: variables.$color-polygon;

        --shadow-color: #{rgba(variables.$color-polygon, 0.125)};
      }
    }

    // Base, Base Goerli, Base Sepolia, Foundry 2

    &--8453,
    &--84531,
    &--84532,
    &--31338 {
      &,
      &:hover {
        background-color: variables.$color-basechain;

        --shadow-color: #{rgba(variables.$color-basechain, 0.125)};
      }
    }

    // Arbitrum One, Arbitrum Goerli

    &--42161,
    &--421613 {
      &,
      &:hover {
        background-color: variables.$color-arbitrum;

        --shadow-color: #{rgba(variables.$color-arbitrum, 0.125)};
      }
    }
  }

  &__light {
    box-shadow: none;

    &:hover {
      filter: none;
    }

    // Ethereum, Goerli, Sepolia, Foundry 1

    &--1,
    &--5,
    &--11155111,
    &--31337 {
      &,
      &:hover {
        color: rgba(variables.$color-ethereum, 0.5);
        background-color: color.scale(variables.$color-ethereum, $lightness: 90%);
      }
    }

    // Optimism, Optimism Goerli

    &--10,
    &--420 {
      &,
      &:hover {
        color: variables.$color-optimism;
        background-color: color.scale(variables.$color-optimism, $lightness: 90%);
      }
    }

    // Polygon, Polygon Mumbai

    &--137,
    &--80001 {
      &,
      &:hover {
        color: variables.$color-polygon;
        background-color: color.scale(variables.$color-polygon, $lightness: 90%);
      }
    }

    // Base, Base Goerli, Base Sepolia, Foundry 2

    &--8453,
    &--84531,
    &--84532,
    &--31338 {
      &,
      &:hover {
        color: rgba(variables.$color-basechain, 0.4);
        background-color: color.scale(variables.$color-basechain, $lightness: 90%);
      }
    }

    // Arbitrum One, Arbitrum Goerli

    &--42161,
    &--421613 {
      &,
      &:hover {
        color: variables.$color-arbitrum;
        background-color: color.scale(variables.$color-arbitrum, $lightness: 90%);
      }
    }
  }
}
