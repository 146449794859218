@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';

.super-header {
  @include layout.max-width;
  @include layout.row;
  justify-content: space-between;
  height: variables.$SUPER_HEADER_HEIGHT;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: white;
  opacity: 0.9;
  transition: variables.$transition-s;

  @media (max-width: variables.$device-md) {
    padding-right: 1rem;
    padding-left: 1rem;
    opacity: 1;
  }

  &__nav {
    @include layout.row(0.25rem);

    @media (max-width: variables.$device-md) {
      gap: 0.125rem;
    }

    &__link {
      border-radius: 4rem;
      padding: 0.125rem 0.75rem;

      &.active {
        background-color: #ad4672;

        &:hover {
          background-color: #7f244b;
        }

        @media (max-width: variables.$device-md) {
          background-color: rgba(variables.$color-purple-dark, 0.375);

          &:hover {
            background-color: rgba(variables.$color-purple-dark, 0.5);
          }
        }
      }

      &:hover {
        background-color: #ad4672;

        @media (max-width: variables.$device-md) {
          background-color: rgba(variables.$color-purple-dark, 0.375);
        }
      }
    }
  }

  span,
  a {
    @include variables.paragraph;
    color: white;
  }

  &__logo {
    a {
      @include layout.row(0.75rem);
    }

    @media (max-width: variables.$device-md) {
      margin-left: 0.5rem;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(53, 0, 205, 25%) 0%, rgba(53, 0, 205, 25%) 100%),
      linear-gradient(90deg, #53acde 0%, #a499ff 43.33%, #ff7f7f 56.76%, #ffaa6b 92.16%, #ffbc58 100%);

    &::after {
      position: absolute;
      bottom: 1px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, #226489 0%, #5346c0 43.33%, #a02f2f 56.76%, #98501c 92.16%, #986518 100%);
      content: ' ';
    }
  }

  .desktop-only {
    @media (max-width: variables.$device-md) {
      display: none;
    }
  }
}
